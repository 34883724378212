.log-reg-page .row{
    margin: auto;
    display: inline-block;
}

.log-reg-page .col-lg-6 {
    margin: 0;
        margin-top: 2rem;
    padding: 2rem;
}

.col-lg-6.kreisais {
    background-color: rgba(0, 0, 255, 0.3);

    border: solid black 3px;
    border-radius: 3rem;

    width: 90vw;
    max-width: 500px;
    
}

.col-lg-6.labais {
    background-color: rgba(0, 0, 255, 0.3);
    border: solid black 3px;
    border-radius: 3rem;

    width: 90vw;
    max-width: 500px;
}