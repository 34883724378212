.welcome{
    overflow-x: hidden;
    margin-top: 2rem;
}


.bundleBox{
  margin-top: 2rem;


  background-color: rgba(0, 0, 255, 0.3);
  border-radius: 2rem 2rem 0 0;
  padding: 0.5rem;
}

.bundleBox h5 {
  margin-top: 1rem;
  font-weight: bold;
}

.bundleBox h6 {
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 0;
}


.bundleBoxPrice{
  background-color: #4CAF50;
  border-radius:0 0 2rem 2rem;
}

.bundleBoxPrice h6 {
  padding: 1rem;
  font-weight: bold;
}

.bundleBox ul{
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.bundleBox li {
  font-size: 1rem;
  margin: 0.4rem;
}


.bundleBox:hover{
  box-shadow: 0 0 2rem black;
  cursor: pointer;
}




.button {
    background-color: #4CAF50;
    border: #4CAF50 solid 1px;
    border-radius: 1rem;
    color: white;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    margin: 2rem 1rem;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
   display: block;
  }

  .buttonDisabled {
    background-color: #4CAF50;
    border: #4CAF50 solid 1px;
    border-radius: 1rem;
    color: white;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    margin: 2rem 1rem;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
   display: block;
   opacity: 0.6;
  }

  .invoiceLink{
    color: white;
    text-decoration: none;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 1rem;
  }