video {
    width: 75vw;
    min-width: 300px;
    max-width: 800px;
    position: relative;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display: block;

    padding-top: 150px;
    padding-bottom: 150px;

    z-index: 1;
}


.video-karte {
    position: relative;
    background-color: rgba(0, 0, 255, 0.3);

}


.custom-shape-divider-top-1635590189 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1635590189 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1635590189 .shape-fill {
    fill: #DCA6FF;
}



.custom-shape-divider-bottom-1635590918 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1635590918 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1635590918 .shape-fill {
    fill:  #DCA6FF;
}

hr.solid {
    border-top: 5px solid #C873FF;
    width: 40%;
    margin-top: 12vh;
    margin-bottom: 12vh;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
  }
  