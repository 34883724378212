.kontakti {
    margin-top: 4rem;
}

.kontakti h4 {
    margin-top: 2rem;
}

  
  
  .kontakti .btn{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 15px;
    border: none;
    width: auto;
    height: auto;
    border-radius: 20px;
    font-size: 18px;

  }

  .button {
        display: inline-block;
  }
  
  .kontakti .facebook {
    background-color: #3b5998;
  }
  
  .kontakti .instagram {
    background-color: #f90644;
  }
  
  .contact .fab {
    margin-right: 5px;
  }
  

.kontakti .map {
    padding-top: 2rem;
}