body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #F0D9FF;
  background-image: url('./images/binding-light.png');


}

h1, h2, h3, h4, h5, h6, a {
  font-family: 'Varela Round', sans-serif;
  color: black;
}



p, li, ul {
  font-family: 'Work Sans', sans-serif;
  color: black;
}

button a {
  font-family: 'Work Sans', sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
