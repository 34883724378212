.videoPaka {
    overflow-x: hidden;
}


.click {
    width: 100vw;
    height: 100vh;

}


.videoPlayer {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
}


.cover{
    z-index: 1;
    width: 100vw;
    height: 90vh;
    background: transparent;
    position: absolute;
    left: 0;
    top:0;

    opacity: 1;
}

.top {
    z-index: 1;
    width: 100vw;
    height: 5rem;
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.3;
}

.bottom {
    z-index: 1;
    width: 17rem;
    height: 4rem;
    background: transparent;
    position: absolute;
    left: 0;
    bottom: 0rem;
    opacity: 0.3;
}

.right {
    z-index: 1;
    width: 7.2rem;
    height: 2.8rem;
    background-color: transparent;
    position: absolute;
    right: 0;
    bottom: 0.4rem;
    opacity: 0.9;
}

.video {
    position: relative;
}

.button {
    background-color: #4CAF50;
    border: #4CAF50 solid 1px;
    border-radius: 1rem;
    color: white;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    margin: 1rem 1rem;

    cursor: pointer;
   display: inline;
  }



  @media only screen and (max-width: 1200px) {
    .right {
        width: 9rem;
        height: 3rem;
    }

  }