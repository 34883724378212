.navBar{
    position: relative;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;

    padding: 1rem;
}

a {
    text-decoration: none;
    color: black;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}



.button {
    background-color: #4CAF50;
    border: #4CAF50 solid 1px;
    border-radius: 1rem;
    color: white;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    margin-left: 2rem;

    cursor: pointer;

    display: inline;

}




@media only screen and (max-width: 990px) {
    
    a {
        display: block;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }
  }