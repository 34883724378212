.accordion-button h3{
   margin-top: 0.5rem;
}


.telefons {
    padding-bottom: 15%;
  }

  .vingrosanasBildeTelefons{
    width: 80%;
    height: auto;
  }

  .telefons li{
    text-align: left;
  }
  .telefons ul{
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .telefons h5{
    text-align: left;
    margin-top: 1rem;
  }

  .telefons p{
    text-align: left;
  }


  .pirmaDalaTelefons .accordion-button{
      background-color: #CF85FF;
  }

  .pirmaDalaTelefons .accordion-body{
    background-color: #e3b8ff;
}

.otraDalaTelefons .accordion-button{
    background-color: #7777fc;
}

.otraDalaTelefons .accordion-body{
  background-color: rgba(0, 0, 255, 0.3);
}