.videoPaka {
    padding-top: 5rem;
    background-color: transparent;
}


.videoPaka ul{
    margin-top: 0.5rem;
    margin-bottom: 2rem;
}

.videoPaka li {
    font-size: 1.2rem;
    margin: 0.7rem;
}

.videoPaka iframe {
    margin-left: auto;
    margin-right: auto;

    width: 100%;
    max-width: 476px;

    height:  476px;
    
    padding-top: 1rem;
    padding-bottom: 1rem;

    padding-left: 1rem;
    padding-right: 1rem;

    display: inline-block;
}


.bundleBox{
    margin-top: 2rem;


    background-color: rgba(0, 0, 255, 0.3);
    border-radius: 2rem 2rem 0 0;
    padding: 0.5rem;
}

.bundleBox h5 {
    margin-top: 1rem;
    font-weight: bold;
}

.bundleBox h6 {
    margin-top: 1rem;
    font-weight: bold;
    margin-bottom: 0;
}


.bundleBoxPrice{
    background-color: #4CAF50;
    border-radius:0 0 2rem 2rem;

    
    margin-bottom: 2rem;
}

.bundleBoxPrice h6 {
    padding: 1rem;
    font-weight: bold;
}

.bundleBox ul{
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.bundleBox li {
    font-size: 1rem;
    margin: 0.4rem;
}


.colLab{
    margin: 2rem 0 0 0;
    padding: 0;
    
}


.button {
    background-color: #4CAF50;
    border: #4CAF50 solid 1px;
    border-radius: 1rem;
    color: white;
    padding: 1rem 4rem;
    width: 20rem;
    text-align: center;
    text-decoration: none;
    font-size: 1.5rem;
    margin: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
   display: block;
  }

.colKreis{
  
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    background-color: rgba(0, 0, 255, 0.3);
    border: solid 1px rgba(0, 0, 255, 0.3);
    border-radius: 3rem;

}