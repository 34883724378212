
.list-group-item{
    text-align: left;
}

.list-group .list-group-item h5 {
    margin: 0.5rem auto 0.5rem;
}


 .pirma-dala .list-group a {
  background-color: #e3b8ff;
}


.pirma-dala .list-group {
    border: solid 1px black;
}

.pirma-dala .list-group-item {
    border: solid 1px black;
    background-color: #e3b8ff;
}

.pirma-dala .list-group .list-group-item:active {
    border: solid 1px black;
    background-color: #CF85FF;
}

.pirma-dala .list-group .list-group-item:hover {
    border: solid 1px black;
    background-color: #CF85FF;
}

.pirma-dala .list-group .list-group-item:focus {
    border: solid 1px black;
    background-color: #CF85FF;
}

.pirma-dala .list-group-item:visited {
    border: solid 1px black;
    background-color: #e3b8ff;
}







  .otraDala .list-group {
      border: solid 1px black;
  }
  
  .otraDala .list-group .list-group-item {
      border: solid 1px black;
      background-color: #7777fc;
  }
  
  .otraDala .list-group .list-group-item:active {
      border: solid 1px black;
      background-color: #4545FB;
  }

  .otraDala .list-group .list-group-item:hover{
    border: solid 1px black;
    background-color: #4545FB;
}
  
  .otraDala .list-group .list-group-item:focus {
      border: solid 1px black;
      background-color: #4545FB;
  }
  
  .otra-dala .list-group .list-group-item:visited {
      border: solid 1px black;
      background-color: #7777fc;
  }



.row {
  
    padding-bottom: 5rem;
}

.row h3 {
    text-align: center;
    margin-top: 1rem;
}


.vingrosanasBildeDators{
    height: auto;
    width: 90%;
}