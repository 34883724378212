
p {
    font-size: 1.1rem;
    opacity: 1;
    color: black;
}


.title{
    margin-right: auto;
    margin-left: auto;
}



.content {
    border: solid 2px black;
    border-radius: 5px;
    text-align: left;
    margin-right: auto;
    margin-left: auto;
    width: 60vw;

}
