.banner {
    position: relative;
  }


  .banner-image {
    width: 100%;
    object-fit: contain;
    position: relative;
  }
  
  .banner-text-head {
    font-family: 'Dancing Script', cursive;
    color: #11324D;
    font-size: 10vw;
    position: absolute;
    left: 5%;
    top: 2vw;
  }
  
  .banner-text-foot {
    font-family: 'Dancing Script', cursive;
    color: #4D2C11;
    font-size: 5vw;
    position: absolute;
    left: 5%;
    top: 14vw;
  }


  .intro {
    position: relative;
    padding: 0% 12% 0%;
    text-align: center;
  }

  .intro p {
    font-size: 1.4rem;
  }

  hr.solid {
    border-top: 5px solid #C873FF;
    width: 40vw;
    min-width: 200px;
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
  }