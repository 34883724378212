.login-form {
    max-width: 360px;
    margin: auto auto;
    padding: 5px;
  }
  .login-form label {
    display: block;
    margin: 30px auto;
  }
  .login-form span {
    display: block;
    margin-bottom: 6px;
  }
  .login-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }


  .button {
    background-color: #4CAF50;
    border: #4CAF50 solid 1px;
    border-radius: 1rem;
    color: white;
    padding: 1rem 1rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    margin: 1rem 1rem;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
   display: block;
  }


  i {
    font-size: 1rem;
  }
